import { ReactNode } from "react";
import styled from "styled-components";

const HiddenSpan = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
`;

interface IIcon {
  className?: string;
  component: React.FC<React.PropsWithChildren>;
  id?: string;
  label?: ReactNode;
  tabIndex?: number;
}

const Icon = ({ className, component, id, label, tabIndex }: IIcon) => {
  const Component = component;
  return (
    <span className={className} id={id} tabIndex={tabIndex}>
      {label && <HiddenSpan>{label}</HiddenSpan>}
      <Component />
    </span>
  );
};

export const DefaultStyledIcon = styled(Icon)`
  display: inline-block;
  height: 1rem;
  width: 1rem;
`;

export default DefaultStyledIcon;
