import { lazy } from "react";
import { IRouteProps } from "shared/types/routes";

const Achievements = lazy(() => import("creators/pages/Achievements"));
const ActiveBrief = lazy(() => import("creators/pages/ActiveBrief"));
const ActiveBriefPreview = lazy(
  () => import("creators/pages/ActiveBriefPreview"),
);
const HomePage = lazy(() => import("creators/pages/Home"));
const Payments = lazy(() => import("creators/pages/Payments"));
const PreviousBrief = lazy(() => import("creators/pages/PreviousBrief"));
const PreviousBriefs = lazy(() => import("creators/pages/PreviousBriefs"));
const Profile = lazy(() => import("creators/pages/Profile"));
const SettingsPage = lazy(() => import("creators/pages/Settings"));
const VUISettingsPage = lazy(() => import("creators/pages/vui/Settings"));

const routes: IRouteProps[] = [
  {
    component: HomePage,
    exact: true,
    path: [
      "/",
      "/brief/:id(\\d+)/:slug?",
      "/brief/:id(\\d+)/teaser/:previewHash",
      "/:page?(onboarding|terms-and-conditions|verify-email)",
    ],
  },
  {
    component: Achievements,
    exact: true,
    path: "/achievements",
    verificationRequired: true,
  },
  {
    component: ActiveBrief,
    exact: true,
    path: [
      "/active-brief",
      "/active-brief/:page(video-\\d+)",
      "/active-brief/release-forms",
      "/active-brief/submit",
    ],
    verificationRequired: true,
  },
  {
    component: ActiveBriefPreview,
    exact: true,
    path: [
      "/brief/:id(\\d+)/full/:previewHash",
      "/brief/:id(\\d+)/full/:previewHash/:page(video-\\d+)",
    ],
    verificationRequired: true,
  },
  {
    component: Payments,
    exact: true,
    path: "/payments",
    verificationRequired: true,
  },
  {
    component: PreviousBrief,
    exact: true,
    path: "/previous-briefs/:id(\\d+)/:slug?",
    verificationRequired: true,
  },
  {
    component: PreviousBriefs,
    exact: true,
    path: "/previous-briefs",
    verificationRequired: true,
  },
  {
    component: Profile,
    exact: true,
    path: ["/profile", "/profile/:id"],
    verificationRequired: true,
  },
  {
    component: SettingsPage,
    path: ["/settings", "/settings/:page?(account|payment|profile)"],
    verificationRequired: true,
    updatedComponent: VUISettingsPage,
  },
];

export default routes;
